import { PointerEvent, useRef, useState } from "react"
import styled from "styled-components"

import { Popper, PopperPlacementType } from "@material-ui/core"

import { cardBoxShadow } from "src/constants/shadows"
import { mColors } from "src/ui/colors"

export function Hoverable({
  openDelay = 300,
  closeDelay = 100,
  trigger,
  content,
  placement = "bottom-start",
}: {
  trigger: React.ReactNode
  content: React.ReactNode
  placement?: PopperPlacementType
  openDelay?: number
  closeDelay?: number
}) {
  const [isOpen, setIsOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement | null>(null)

  const openTimerRef = useRef<NodeJS.Timer | null>(null)
  const closeTimerRef = useRef<NodeJS.Timer | null>(null)

  function open() {
    if (closeTimerRef.current) {
      clearTimeout(closeTimerRef.current)
    }

    openTimerRef.current = setTimeout(() => {
      setIsOpen(true)
    }, openDelay)
  }

  function close() {
    if (openTimerRef.current) {
      clearTimeout(openTimerRef.current)
    }

    closeTimerRef.current = setTimeout(() => {
      setIsOpen(false)
    }, closeDelay)
  }

  return (
    <StyledHoverable>
      <StyledTrigger
        ref={anchorRef}
        onPointerEnter={excludeTouch(open)}
        onPointerLeave={excludeTouch(close)}
      >
        {trigger}
      </StyledTrigger>
      <StyledHoverablePopper
        open={isOpen}
        anchorEl={anchorRef.current}
        placement={placement}
        onPointerEnter={open}
        onPointerLeave={close}
      >
        {content}
      </StyledHoverablePopper>
    </StyledHoverable>
  )
}

function excludeTouch(eventHandler: (e: PointerEvent) => void) {
  return (e: PointerEvent) => {
    return e.type === "touch" ? undefined : eventHandler(e)
  }
}

const StyledHoverable = styled.div`
  position: relative;
`

const StyledTrigger = styled.div`
  display: inline-block;
`

const StyledHoverablePopper = styled(Popper)`
  background-color: ${mColors.neutralLight};
  border: 1px solid ${mColors.divider};
  border-radius: 8px;
  ${cardBoxShadow};
  max-width: 350px;
`
